.App {
  text-align: center;
}

.app-container{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    /*background-color: #EE333E;*/
    background-color: #FFF;
}

.App-header {
  background-color: #EEEEEE;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #EE333E;
}

.App-link {
  color: #09d3ac;
}

.App-progress {
  max-width: 300px;
  min-width: 300px;
}

div.ant-popover-inner-content > div > div:nth-child(3) {
  display: none;
}
